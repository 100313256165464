var GROWLINFO = 'success';
var GROWLWARNING = 'info';
var GROWLERROR = 'error';

function GrowlMessages (divId, contentId, thisName, options) {
    this.divId = divId;
    this.contentId = contentId;
    this.thisName = thisName;
    this.messageQueue = new Array();
    this.options = options;
    if (this.options == null) {
        this.options = {};
    }

    // state
    this.showingQueue = false;
    this.showingIndex = null;
    this.messageDuration = 7000;
    this.messageFadeInTime = 600;
    this.messageFadeOutTime = 600;
    var easing = 'easeInOutCubic';

    // $("div#userMessages").fadeIn(900);
    // functions
    this.initialize = function () {
        if (this.messageQueue.length > 0) {
            this.showQueue();
        }
    };
    this.addMessageToQueue = function (messageObject) {
        this.messageQueue[this.messageQueue.length] = messageObject;
    };
    this.addInfoMessage = function (message) {
        this.addMessageToQueue(new GrowlMessage(message, GROWLINFO));
    };
    this.addWarningMessage = function (message) {
        this.addMessageToQueue(new GrowlMessage(message, GROWLWARNING));
    };
    this.addErrorMessage = function (message) {
        this.addMessageToQueue(new GrowlMessage(message, GROWLERROR));
    };
    this.showMessage = function (growlMessage) {
        this.addMessageToQueue(growlMessage);
        this.showQueue();
    };
    this.showInfoMessage = function (message) {
        this.addInfoMessage(message);
        this.showQueue();
    };
    this.showWarningMessage = function (message) {
        this.addWarningMessage(message);
        this.showQueue();
    };
    this.showErrorMessage = function (message) {
        this.addErrorMessage(message);
        this.showQueue();
    };
    this.showAll = function (cometRequest) {
        var content = '';
        var errorMessages = cometRequest.errorMessages;
        if (errorMessages != null && errorMessages.length > 0) {
            for (var i = 0; i < errorMessages.length; i++) {
                this.addErrorMessage(strWeb(errorMessages[i]));
            }
        }
        var warningMessages = cometRequest.warningMessages;
        if (warningMessages != null && warningMessages.length > 0) {
            for (var i = 0; i < warningMessages.length; i++) {
                this.addWarningMessage(strWeb(warningMessages[i]));
            }
        }
        var infoMessages = cometRequest.infoMessages;
        if (infoMessages != null && infoMessages.length > 0) {
            for (var i = 0; i < infoMessages.length; i++) {
                this.addInfoMessage(strWeb(infoMessages[i]));
            }
        }
        this.showQueue();
    };
    this.showAllRestMessages = function (response) {
        for (var index in response.messages) {
            var message = response.messages[index];

            if (message.type == 'WARNING') {
                this.addWarningMessage(message.detail);
            } else {
                this.addInfoMessage(message.detail); // TODO Format message?
            }
        }

        for (var index in response.errors) {
            this.addErrorMessage(response.errors[index].detail);
        }

        this.showQueue();
    };
    this.showQueue = function () {
        if (this.showingQueue) {
            // already running. messsage will show after current one.
            return;
        }
        this.showingQueue = true;
        this.run();
    };
    this.run = function () {
        if (this.showingIndex != null) {
            var tempThis = this;
            $('#' + this.divId).slideUp(this.messageFadeOutTime, easing, function () {
                if (tempThis.showingIndex + 2 > tempThis.messageQueue.length) {
                    tempThis.showingIndex = null;
                    tempThis.showingQueue = false;
                    tempThis.messageQueue = new Array();
                } else {
                    tempThis.private_showMessageIndex(tempThis.showingIndex + 1);
                }
            });
        } else {
            if (this.messageQueue.length > 0) {
                this.private_showMessageIndex(0);
            } else {
                this.showingQueue = false;
            }
        }
    };
    this.private_showMessageIndex = function (messageIndex) {
        var self = this;
        this.showingIndex = messageIndex;
        var message = this.messageQueue[messageIndex];
        dynamic.setDivIdContent(this.contentId, message.message);
        if (this.options.defaultCssClasses == null) {
            dynamic.removeClass(this.divId, GROWLINFO);
            dynamic.removeClass(this.divId, GROWLWARNING);
            dynamic.removeClass(this.divId, GROWLERROR);
        } else {
            dynamic.removeClass(this.divId);
            dynamic.addClass(this.divId, this.options.defaultCssClasses);
        }
        dynamic.addClass(this.divId, message.cssClass);

        // dynamic.centerDiv(this.divId);
        if (window.tbg && window.tbg.notifications) {
            if (window.tbg.notifications.trigger(window.tbg.notifications.GROWL_NOTIFICATION, new GrowlNotification(message))) {
                showGrowl();
            } else {
                this.run();
            }
        } else {
            showGrowl();
        }

        function showGrowl () {
            $('#' + self.divId).slideDown(self.messageFadeInTime, easing);
            setTimeout(self.thisName + '.run()', self.messageDuration);
        }
    };

    //
    var tempThis = this;
    $(window).load(function () {
        tempThis.initialize();
    });
}

/*
 * GrowlMessage object
 *
 */
function GrowlMessage (message, cssClass) {
    this.message = message;
    this.cssClass = cssClass;
}

/*
 * GrowlNotification object
 *
 */
function GrowlNotification (growlMessage) {
    this.message = growlMessage.message;

    switch (growlMessage.cssClass) {
        case GROWLWARNING:
            this.type = 'INFO';
            break;
        case GROWLERROR:
            this.type = 'ERROR';
            break;
        case GROWLINFO:
        default:
            this.type = 'SUCCESS';
            break;
    }
}
