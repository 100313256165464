function MultiChooser (thisName, leftDivId, rightDivId, selectedHiddenField, editPanelId) {
    this.thisName = thisName;
    this.leftDivId = leftDivId;
    this.leftDiv = null;
    this.rightDivId = rightDivId;
    this.rightDiv = null;
    this.selectedHiddenField = selectedHiddenField;
    this.editPanel = new MultiChooserEditPanel(thisName, editPanelId);
    this.leftList = new Array();
    this.rightList = new Array();
    this.checkColumns = new Array();
    this.entryFieldColumns = new Array();

    //
    this.leftSelected = false;
    this.rightSelected = false;

    // public functions
    this.addLeftItem = MultiChooser_addLeftItem;
    this.addRightItem = MultiChooser_addRightItem;
    this.addCheckColumn = MultiChooser_addCheckColumn;
    this.addEntryFieldColumn = MultiChooser_addEntryFieldColumn;
    this.selectClicked = MultiChooser_selectClicked;
    this.deselectClicked = MultiChooser_deselectClicked;
    this.moveUpClicked = MultiChooser_moveUpClicked;
    this.moveDownClicked = MultiChooser_moveDownClicked;
    this.toggleLeft = MultiChooser_toggleLeft;
    this.toggleRight = MultiChooser_toggleRight;

    // private functions
    this.leftItemSelect = MultiChooser_leftItemSelect;
    this.rightItemSelect = MultiChooser_rightItemSelect;
    this.moveItems = MultiChooser_moveItems;
    this.setSelectedHiddenField = MultiChooser_setSelectedHiddenField;
    this.setCheckedHiddenFields = MultiChooser_setCheckedHiddenFields;
    this.generateLeftList = MultiChooser_generateLeftList;
    this.generateRightList = MultiChooser_generateRightList;
    this.check = MultiChooser_check;
    this.editEF = MultiChooser_editEF;
    this.initialize = MultiChooser_initialize;
    this.editorSave = MultiChooser_editorSave;
    this.editorCancel = MultiChooser_editorCancel;
    this.listenForDisplayInactiveCheckboxChange = MultiChooser_listenForDisplayInactiveCheckboxChange;

    var tempThis = this;
    $(window).load(function () {
        tempThis.initialize();
    });
}
function MultiChooser_initialize () {
    this.leftDiv = $('#' + this.leftDivId)[0];
    this.rightDiv = $('#' + this.rightDivId)[0];
    this.generateLeftList();
    this.generateRightList();
    this.setSelectedHiddenField();
    this.setCheckedHiddenFields();
}
function MultiChooser_generateLeftList () {
    var content = '<div class="mc-search-wrapper"><label style="float:right; padding: 4px 4px 0 0;"><input type="checkbox" class="' + this.thisName + 'activeinactivecb">Show inactive</label><input placeholder="Search..." class="multichooser-search"/></div><ul class="mc-list">';

    for (var i = 0; i < this.leftList.length; i++) {
        var item = this.leftList[i];
        var aId = this.thisName + item.id;
        var aClass = item.selected ? 'on' : 'off';
        var itemDisplayStyle = 'block';
        if (item.name.indexOf('Inactive') > -1) {
            itemDisplayStyle = 'none';
        }
        content += '<li style="display:' + itemDisplayStyle + ';"><a id="' + aId + '" href="javascript:' + this.thisName + '.leftItemSelect(' + i + ')" class="searchableName ' + aClass + '">'
				+ strWeb(item.name) + '</a></li>';
    }
    content += '</ul>';
    dynamic.setDivIdContent(this.leftDivId, content);
    this.listObj = new List(this.leftDivId, {
        valueNames: ['searchableName'],
        listClass: 'mc-list',
        searchClass: 'multichooser-search',
        page: this.leftList.length + 1
    });

    this.listenForDisplayInactiveCheckboxChange(this.thisName, this.leftDivId);
}
function MultiChooser_listenForDisplayInactiveCheckboxChange (thisName, leftDivId) {
    $('.' + thisName + 'activeinactivecb').change(function () {
        jQuery('#' + leftDivId + ' ul.mc-list li').each(function () {
            var $li = jQuery(this);

            // if the checkbox is checked, display all items
            if ($('.' + thisName + 'activeinactivecb').is(':checked')) {
                $li.css('display', 'block');
            } else {
                // if the checkbox is unchecked, display all items, but hide the ones that are Inactive
                if ($li.text().indexOf('(Inactive)') > -1) {
                    $li.css('display', 'none');
                } else {
                    $li.css('display', 'block');
                }
            }
        });
    });
}
function MultiChooser_generateRightList () {
    var content = '<table width="100%" border="0" cellspacing="0" class="multiChooserIncluded">\n';
    if (this.checkColumns.length > 0 || this.entryFieldColumns.length > 0) {
        content += '<tr>\n';
        content += '<th></th>\n';
        for (var j = 0; j < this.checkColumns.length; j++) {
            content += '<th>';
            content += this.checkColumns[j].header;
            content += '</th>\n';
        }
        for (var j = 0; j < this.entryFieldColumns.length; j++) {
            content += '<th>';
            content += this.entryFieldColumns[j].header;
            content += '</th>\n';
        }
        content += '</tr>\n';
    }

    for (var i = 0; i < this.rightList.length; i++) {
        var item = this.rightList[i];
        var aId = this.thisName + item.id;
        content += '<tr>\n';
        content += '<td>';
        var aClass = item.selected ? 'on' : 'off';
        content += '<a id="' + aId + '" href="javascript:' + this.thisName + '.rightItemSelect(' + i + ')" class="' + aClass + '">' + item.name + '</a>';
        content += '</td>\n';
        for (var j = 0; j < this.checkColumns.length; j++) {
            var aClass = item.checks[j] ? 'checkOn' : 'checkOff';
            content += '<td class="defaultCheck">';
            content += '<a href="javascript:' + this.thisName + '.check(' + i + ',' + j + ')" title="Check For Default Selection" class="' + aClass + '">&nbsp;</a>';
            content += '</td>\n';
        }
        for (var j = 0; j < this.entryFieldColumns.length; j++) {
            content += '<td class="editableValue">';
            content += '<a id="' + (this.thisName + '_' + i + '_' + j) + '" href="javascript:' + this.thisName + '.editEF(' + i + ',' + j + ')" title="Edit The Maximum Quantity">'
					+ item.getEntryFieldValue(j) + '</a>';
            content += forms.ef(this.selectedHiddenField + 'EF_' + i + '_' + j, item.getEntryFieldValue(j), {
                type: 'hidden'
            });
            content += '</td>\n';
        }
        content += '</tr>\n';
    }
    content += '</table>\n';
    dynamic.setDivIdContent(this.rightDivId, content);
}
function MultiChooser_leftItemSelect (index) {
    var item = this.leftList[index];
    if (item.selected) {
        dynamic.setClass(this.thisName + item.id, 'off');
    } else {
        dynamic.setClass(this.thisName + item.id, 'on');
    }
    item.selected = !item.selected;
}

function MultiChooser_rightItemSelect (index) {
    var item = this.rightList[index];
    if (item.selected) {
        dynamic.setClass(this.thisName + item.id, 'off');
    } else {
        dynamic.setClass(this.thisName + item.id, 'on');
    }
    item.selected = !item.selected;
}
function MultiChooser_addLeftItem (item) {
    this.leftList[this.leftList.length] = item;
}
function MultiChooser_addRightItem (item) {
    this.rightList[this.rightList.length] = item;
}
function MultiChooser_addCheckColumn (item) {
    this.checkColumns[this.checkColumns.length] = item;
}
function MultiChooser_addEntryFieldColumn (item) {
    this.entryFieldColumns[this.entryFieldColumns.length] = item;
}
function MultiChooser_selectClicked () {
    this.moveItems(true);
}
function MultiChooser_deselectClicked () {
    this.moveItems(false);
}
function MultiChooser_moveUpClicked () {
    var items = this.rightList;
    var selected = new Array();
    var unselected = new Array();
    var newList = new Array();
    var upFromIndex = null;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.selected) {
            if (selected.length == 0) {
                upFromIndex = i;
            }
            selected[selected.length] = item;
        } else {
            unselected[unselected.length] = item;
        }
    }
    if (selected.length > 0 && unselected.length > 0 && upFromIndex > 0) {
        for (var i = 0; i < unselected.length; i++) {
            var item = unselected[i];
            if (upFromIndex == (i + 1)) {
                for (var j = 0; j < selected.length; j++) {
                    newList[newList.length] = selected[j];
                }
            }
            newList[newList.length] = item;
        }
        this.rightList = newList;
        this.generateRightList();
        this.setSelectedHiddenField();
    }
}
function MultiChooser_moveDownClicked () {
    var items = this.rightList;
    var selected = new Array();
    var unselected = new Array();
    var newList = new Array();
    var downFromIndex = null;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.selected) {
            downFromIndex = i;
            selected[selected.length] = item;
        } else {
            unselected[unselected.length] = item;
        }
    }
    if (selected.length > 0 && unselected.length > 0 && downFromIndex < items.length) {
        for (var i = 0; i < unselected.length; i++) {
            var item = unselected[i];
            newList[newList.length] = item;
            if (downFromIndex == i) {
                for (var j = 0; j < selected.length; j++) {
                    newList[newList.length] = selected[j];
                }
            }
        }
        if (downFromIndex >= unselected.length) {
            for (var j = 0; j < selected.length; j++) {
                newList[newList.length] = selected[j];
            }
        }
        this.rightList = newList;
        this.generateRightList();
        this.setSelectedHiddenField();
    }
}

function MultiChooser_setSelectedHiddenField () {
    var items = this.rightList;
    var newVal = '';
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (i > 0) {
            newVal += ',';
        }
        newVal += item.id;
    }
    setFieldValue(this.selectedHiddenField, newVal);
}
function MultiChooser_setCheckedHiddenFields () {
    var items = this.rightList;
    for (var j = 0; j < this.checkColumns.length; j++) {
        var newVal = '';
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.checks[j]) {
                if (newVal != '') {
                    newVal += ',';
                }
                newVal += item.id;
            }
        }
        setFieldValue(this.selectedHiddenField + 'Checked' + j, newVal);
    }
}
function MultiChooser_moveItems (isMoveRight) {
    var sourceList = isMoveRight ? this.leftList : this.rightList;
    var destList = isMoveRight ? this.rightList : this.leftList;
    var newSource = new Array();
    var newDest = new Array();
    var debug = '';
    for (var i = 0; i < destList.length; i++) {
        var item = destList[i];
        newDest[newDest.length] = item;
    }
    for (var i = 0; i < sourceList.length; i++) {
        var item = sourceList[i];
        if (item.selected) {
            item.selected = false;
            for (var j = 0; j < this.checkColumns.length; j++) {
                item.checks[j] = false;
            }
            newDest[newDest.length] = item;
        } else {
            newSource[newSource.length] = item;
        }
    }
    this.leftList = isMoveRight ? newSource : newDest;
    this.rightList = isMoveRight ? newDest : newSource;
    this.generateLeftList();
    this.generateRightList();
    this.setSelectedHiddenField();
    this.setCheckedHiddenFields();
}
function MultiChooser_toggleLeft () {
    var list = this.leftList;
    this.leftSelected = !this.leftSelected;
    for (var i = 0; i < list.length; i++) {
        var item = list[i];
        item.selected = this.leftSelected;
    }
    this.generateLeftList();
}
function MultiChooser_toggleRight () {
    var list = this.rightList;
    this.rightSelected = !this.rightSelected;
    for (var i = 0; i < list.length; i++) {
        var item = list[i];
        item.selected = this.rightSelected;
    }
    this.generateRightList();
}
function MultiChooser_check (index, colIndex) {
    for (var i = 0; i < this.rightList.length; i++) {
        var item = this.rightList[i];
        if (index == i) {
            item.checks[colIndex] = !item.checks[colIndex];
        } else if (!this.checkColumns[colIndex].multiCheck) {
            item.checks[colIndex] = false;
        }
    }
    this.generateRightList();
    this.setCheckedHiddenFields();
}
function MultiChooser_editEF (index, colIndex) {
    this.editIndex = index;
    this.editCol = colIndex;
    var item = this.rightList[index];
    var efValue = item.getEntryFieldValue(colIndex);
    this.editPanel.openEntryField($('#' + this.thisName + '_' + index + '_' + colIndex).offset(), efValue, this.entryFieldColumns[colIndex].header);
    $.connect(this.editPanel, 'onsave', this, 'editorSave');
    $.connect(this.editPanel, 'cancel', this, 'editorCancel');
}
function MultiChooser_editorSave () {
    var newValue = getFormItemValue(this.editPanel.divId + 'EF');
    var item = this.rightList[this.editIndex];
    item.entryFields[this.editCol] = newValue;
    this.editorCancel();
    this.generateRightList();
}
function MultiChooser_editorCancel () {
    $.disconnect(this.editPanel, 'onsave', this, 'editorSave');
    $.disconnect(this.editPanel, 'cancel', this, 'editorCancel');
    this.editIndex = null;
    this.editCol = null;
}

/*
 *
 *
 */
function MultiChooserItem (id, name, columnValues) {
    this.id = id;
    this.name = name;
    this.selected = false;

    var workingColumnValues = columnValues;
    if (workingColumnValues == null) {
        workingColumnValues = {};
    }
    this.checks = workingColumnValues.checks;
    if (this.checks == null) {
        this.checks = new Array();
    }
    this.entryFields = workingColumnValues.entryFields;
    if (this.entryFields == null) {
        this.entryFields = new Array();
    }

    // functions
    this.getEntryFieldValue = MultiChooserItem_getEntryFieldValue;
}
function MultiChooserItem_getEntryFieldValue (index) {
    var val = this.entryFields[index];
    if (val == null) {
        this.entryFields[index] = '';
    }
    return this.entryFields[index];
}

/*
 *
 *
 */
function MultiChooserCheckColumn (multiCheck, header) {
    this.multiCheck = multiCheck;
    this.header = header;
}

/*
 *
 *
 */
function MultiChooserEntryFieldColumn (header, size) {
    this.header = header;
    this.size = size;
}

/*
 *
 *
 */
function MultiChooserEditPanel (chooserName, divId) {
    this.chooserName = chooserName;
    this.divId = divId;

    //
    this.openEntryField = MultiChooserEditPanel_openEntryField;
    this.close = MultiChooserEditPanel_close;
    this.onsave = MultiChooserEditPanel_onsave;
    this.save = MultiChooserEditPanel_save;
    this.cancel = MultiChooserEditPanel_cancel;
}
function MultiChooserEditPanel_openEntryField (pos, value, columnName) {
    var content = '';
    content += '<div>Enter a new value for ' + columnName + '</div>\n';
    content += '<p>' + forms.ef(this.divId + 'EF', value) + '</p>\n';
    content += '<div>';
    content += forms.button('Save', {
        onclick: this.chooserName + '.editPanel.save()',
        cssClass: 'save'
    });
    content += forms.button('Cancel', {
        onclick: this.chooserName + '.editPanel.cancel()',
        cssClass: 'cancel'
    });
    content += '</div>\n';
    dynamic.setDivIdContent(this.divId, content);
    $('#' + this.divId).css({
        top: pos.top + 'px',
        left: pos.left + 'px'
    });
    $('#' + this.divId).fadeIn();
}
function MultiChooserEditPanel_close () {
    // dynamic.addClass(this.divId, 'hideIt');
    $('#' + this.divId).fadeOut();
}
function MultiChooserEditPanel_save () {
    this.close();
    var newValue = getFormItemValue(this.divId + 'EF');
    this.onsave();
}
function MultiChooserEditPanel_cancel () {
    this.close();
}
function MultiChooserEditPanel_onsave () {
    // listeners
}
