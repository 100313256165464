function UserPermissions (cuserpermissions) {
    this.cuserpermissions = cuserpermissions;

    // functions
    this.hasView = UserPermissions_hasView;
    this.hasEdit = UserPermissions_hasEdit;
    this.hasCreate = UserPermissions_hasCreate;
    this.getEntity = UserPermissions_getEntity;
}
function UserPermissions_hasView (entityName) {
    var entity = this.getEntity(entityName);
    if (entity == null) {
        return false;
    }
    return entity.get('view');
}
function UserPermissions_hasEdit (entityName) {
    var entity = this.getEntity(entityName);
    if (entity == null) {
        return false;
    }
    return entity.get('edit');
}
function UserPermissions_hasCreate (entityName) {
    var entity = this.getEntity(entityName);
    if (entity == null) {
        return false;
    }
    return entity.get('create');
}
function UserPermissions_getEntity (entityName) {
    var cuserpermissions = this.cuserpermissions;
    var map = cuserpermissions.get('permissions');
    return map.get(entityName);
}
