function QuickAdd (thisName, fieldName, ddDivId, quickDivId) {
    this.thisName = thisName;
    this.fieldName = fieldName;
    this.ddDivId = ddDivId;
    this.quickDivId = quickDivId;
    this.addFieldName = thisName + 'quickAddEF';

    // settings

    // functions
    this.initialize = QuickAdd_initialize;
    this.generateDropDown = QuickAdd_generateDropDown;
    this.generateAdd = QuickAdd_generateAdd;
    this.generateAddField = QuickAdd_generateAddField;
    this.showFieldClicked = QuickAdd_showFieldClicked;
    this.addClicked = QuickAdd_addClicked;
    this.cancelClicked = QuickAdd_cancelClicked;
    this.onAdd = QuickAdd_onAdd;
    this.addSuccessful = QuickAdd_addSuccessful;

    // init
}
function QuickAdd_initialize (clist, cobject) {
    this.generateDropDown(clist, cobject);
    this.generateAdd();
}
function QuickAdd_generateDropDown (clist, cobject) {
    var content = forms.dd(this.fieldName, clist, cobject);
    dynamic.setDivIdContent(this.ddDivId, content);
}
function QuickAdd_generateAdd () {
    var content = '';
    content += '<a href="javascript:' + this.thisName + '.showFieldClicked()" class="quickAdd">Add New</a>';
    dynamic.setDivIdContent(this.quickDivId, content);
}
function QuickAdd_generateAddField () {
    var content = '';
    content += '<div class="quickAddField">\n';
    content += ' <input name="' + this.addFieldName + '" type="text" size="20" />\n';
    content += ' <button type="button" class="secondary" onclick="' + this.thisName + '.addClicked()">Add</button> <button type="button" class="secondary" onclick="' + this.thisName + '.cancelClicked()">Cancel</button>\n';
    content += '</div>\n';
    dynamic.setDivIdContent(this.quickDivId, content);
    focusOn(this.addFieldName);
}
function QuickAdd_showFieldClicked () {
    this.generateAddField();
}
function QuickAdd_addClicked () {
    var validator = new Validator();
    validator.addItem(newValidateItem(this.addFieldName, 'New Value'));
    if (validator.isValid()) {
        var newValue = getFormItemValue(this.addFieldName);
        this.onAdd(newValue);
    }
}
function QuickAdd_cancelClicked () {
    this.generateAdd();
}
function QuickAdd_onAdd (newValue) {
    // event listeners
}
function QuickAdd_addSuccessful (clist, cobject) {
    // potentially show user message
    this.generateDropDown(clist, cobject);
    this.generateAdd();
}
