function ObjectList (thisName, tabDivId, contentDivId, selectedValue, perPage) {
    this.thisName = thisName;
    this.tabDivId = tabDivId;
    this.contentDivId = contentDivId;
    this.selectedValue = selectedValue;
    this.tabs = new Array();

    // settings
    this.perPage = 75;
    if (perPage != null) {
        this.perPage = perPage;
    }

    // state
    this.tabIndex = 0;

    // functions
    this.addTab = ObjectList_addTab;
    this.initialize = ObjectList_initialize;
    this.generateTabs = ObjectList_generateTabs;
    this.generateContent = ObjectList_generateContent;
    this.tabClicked = ObjectList_tabClicked;
    this.nextPage = ObjectList_nextPage;
    this.prevPage = ObjectList_prevPage;

    // init
    var tempThis = this;
    $(window).load(function () {
        tempThis.initialize();
    });
}
function ObjectList_initialize () {
    for (var i = 0; i < this.tabs.length; i++) {
        var tab = this.tabs[i];
        tab.initialize();
    }

    // determine active tab based on objectid.  otherwise the first tab
    if (this.selectedValue != null) {
        for (var i = 0; i < this.tabs.length; i++) {
            var tab = this.tabs[i];
            if (tab.hasSelectedValue(this.selectedValue)) {
                this.tabIndex = i;
                break;
            }
        }
    }

    this.generateTabs();
    this.generateContent();
}
function ObjectList_addTab (tab) {
    tab.parent = this;
    this.tabs[this.tabs.length] = tab;
}
function ObjectList_generateTabs () {
    var content = '';
    for (var i = 0; i < this.tabs.length; i++) {
        var tab = this.tabs[i];

        // tab
        var cssClass = '';
        if (this.tabIndex == i) {
            cssClass = 'class="on"';
        }
        content += '<a href="javascript:' + this.thisName + '.tabClicked(' + i + ')" ' + cssClass + '>' + tab.name + '</a>\n';
    }
    dynamic.setDivIdContent(this.tabDivId, content);
}
function ObjectList_generateContent () {
    var tab = this.tabs[this.tabIndex];
    tab.generateContent();
}
function ObjectList_tabClicked (index) {
    this.tabIndex = index;
    this.generateTabs();
    this.generateContent();
}
function ObjectList_nextPage () {
    this.tabs[this.tabIndex].nextPage();
}
function ObjectList_prevPage () {
    this.tabs[this.tabIndex].prevPage();
}

/*
 * ObjectListTab
 */
function ObjectListTab (name) {
    this.parent = null;
    this.name = name;
    this.items = new Array();
    this.pages = new Array();

    // state
    this.pageIndex = 0;
    this.paging = false;

    // functions
    this.addItem = ObjectListTab_addItem;
    this.addPage = ObjectListTab_addPage;
    this.initialize = ObjectListTab_initialize;
    this.generateContent = ObjectListTab_generateContent;
    this.hasSelectedValue = ObjectListTab_hasSelectedValue;
    this.nextPage = ObjectListTab_nextPage;
    this.prevPage = ObjectListTab_prevPage;
}
function ObjectListTab_addItem (item) {
    this.items[this.items.length] = item;
}
function ObjectListTab_addPage (page) {
    this.pages[this.pages.length] = page;
}
function ObjectListTab_initialize () {
    if (this.items.length > this.parent.perPage) {
        this.paging = true;
        var perPage = this.parent.perPage;
        var pageCount = parseInt(this.items.length / perPage);
        for (var i = 0; i < pageCount; i++) {
            var page = new ObjectListPage();
            var start = i * perPage;
            var end = start + perPage;
            page.items = this.items.slice(start, end);
            this.addPage(page);
        }

        // remainder
        if (this.items.length % this.parent.perPage != 0) {
            var page = new ObjectListPage();
            page.items = this.items.slice(perPage * pageCount);
            pageCount += 1;
            this.addPage(page);
        }
    }
}
function ObjectListTab_hasSelectedValue (selectedValue) {
    if (this.paging) {
        for (var i = 0; i < this.pages.length; i++) {
            var page = this.pages[i];
            if (page.hasSelectedValue(selectedValue)) {
                this.pageIndex = i;
                return true;
            }
        }
    } else {
        for (var i = 0; i < this.items.length; i++) {
            var item = this.items[i];
            if (item.value == selectedValue) {
                return true;
            }
        }
    }
    return false;
}
function ObjectListTab_generateContent () {
    var selectedValue = this.parent.selectedValue;
    var content = '';
    content += '<ul class="contentList">\n';
    var items = this.items;
    if (this.paging) {
        items = this.pages[this.pageIndex].items;
    }
    for (var j = 0; j < items.length; j++) {
        var item = items[j];
        var aCSSClass = '';
        if (selectedValue != null && item.value == selectedValue) {
            aCSSClass = 'class="selected"';
        }
        var liCSSClass = '';
        if (item.cssClass != null) {
            liCSSClass = 'class="' + item.cssClass + '"';
        }
        var clickAction = 'editClicked(\'' + item.value + '\')';
        if (item.viewReadOnly) {
            clickAction = 'viewReadOnlyClicked(\'' + item.value + '\')';
        }
        content += '<li ' + liCSSClass + '><a href="javascript:' + clickAction + '" ' + aCSSClass + '>' + item.name + '</a></li>\n';
    }
    content += '</ul>\n';
    if (this.paging) {
        content += '<div class="objectListPaging">\n';
        content += '<a href="javascript:' + this.parent.thisName + '.nextPage()" class="pager next">Next ></a>\n';
        content += '<a href="javascript:' + this.parent.thisName + '.prevPage()" class="pager prev">< Prev</a>\n';
        content += (this.pageIndex + 1) + ' of ' + this.pages.length;
        content += '</div>\n';
    }
    dynamic.setDivIdContent(this.parent.contentDivId, content);
}
function ObjectListTab_nextPage () {
    if (this.pageIndex < (this.pages.length - 1)) {
        this.pageIndex += 1;
        this.generateContent();
    }
}
function ObjectListTab_prevPage () {
    if (this.pageIndex > 0) {
        this.pageIndex += -1;
        this.generateContent();
    }
}

/*
 * ObjectListPage
 */
function ObjectListPage () {
    this.items = new Array();

    // functions
    this.addItem = ObjectListPage_addItem;
    this.hasSelectedValue = ObjectListPage_hasSelectedValue;
}
function ObjectListPage_addItem (item) {
    this.items[this.items.length] = item;
}
function ObjectListPage_hasSelectedValue (selectedValue) {
    for (var i = 0; i < this.items.length; i++) {
        var item = this.items[i];
        if (item.value == selectedValue) {
            return true;
        }
    }
    return false;
}

/*
 * ObjectListItem
 */
function ObjectListItem (name, value, readOnly, cssClass) {
    this.name = name;
    this.value = value;
    this.viewReadOnly = false;
    if (readOnly == true) {
        this.viewReadOnly = true;
    }
    this.cssClass = cssClass;
}
